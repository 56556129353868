<template>
  <v-dialog v-model="modalData.dialog" persistent max-width="650px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="ml-4 mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New user group
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-model="selectedLocale"
                :items="modalData.languages"
                :id="dynamicID"
              >
                <template slot="selection" slot-scope="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="selectedLocale.flag" alt="" />
                  </span>
                  {{ slotProps.item.name }}
                </template>
                <template v-slot:item="slotProps">
                  <span class="symbol symbol-20 mr-3">
                    <img :src="slotProps.item.flag" alt="" />
                  </span>
                  <span class="navi-text">{{ slotProps.item.name }}</span>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" sm="3" md="3">
              <v-select
                v-if="statuses.system"
                v-model="formModel.status"
                :items="statuses.system.userGroup"
                label="Status"
                item-text="value"
                item-value="key"
                :id="dynamicID"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="8">
                <v-text-field
                  v-if="formModel.translations[selectedLocale.lang]"
                  v-model="formModel.translations[selectedLocale.lang].name"
                  :key="selectedLocale.lang + '-name'"
                  :rules="nameRules"
                  label="User group name"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <label class="typo__label">Select Users</label>
                <multiselect
                  v-model="formModel.users"
                  :options="modalData.users"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Pick some"
                  label="email"
                  track-by="email"
                  :id="dynamicID"
                >
                </multiselect>
              </v-col>
              <v-col cols="12" sm="6" md="4"> </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
            v-if="
              (permissionCan('create') && this.modalData.editedIndex === -1) ||
              permissionCan('update')
            "
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-dialog>
</template>

<script>
import Multiselect from "vue-multiselect";
import i18nService from "@/core/services/i18n.service.js";
const TRANSLATED_ATRIBUTES = ["name"];

export const initialFormData = {
  id: "",
  status: 1,
  translations: {},
  users: [],
};

export default {
  name: "UserGroupForm",
  components: { Multiselect },
  props: ["modalData", "statuses", "permissions", "loader"],
  data() {
    return {
      formModel: Object.assign({}, initialFormData),
      formValid: false,
      languages: i18nService.languages,
      selectedLocale: i18nService.languages.find((item) => {
        return item.lang == i18nService.getActiveLanguage();
      }),
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => v.length > 3 || "Name must be min 3 characters",
      ],
    };
  },
  mounted: function () {},
  computed: {
    formTitle() {
      return this.modalData.editedIndex === -1
        ? "New User Group"
        : "Edit User Group";
    },
    // formModel() {
    //   this.setTranslatedAttributes;
    //   return this.modalData.editedItem;
    // },

    dynamicID() {
      // return 'dynamicID-' + Math.floor(Math.random() * Date.now().toString());

      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },

  watch: {
    modalData: {
      deep: true,
      handler(value) {
        this.formModel = Object.assign({}, initialFormData);
        this.formModel = value.editedItem;
        this.setTranslatedAttributes();
      },
    },
  },

  methods: {
    resetForm() {
      this.formModel = Object.assign({}, initialFormData);
      this.formModel.users = [];
      this.setTranslatedAttributes(true);
    },

    handleCloseModalForm() {
      this.resetForm();
      this.$emit("closeModalForm");
    },
    handleNew() {
      this.resetForm();
    },
    handleSaveModalForm() {
      this.$refs.form.validate();
      if (this.formValid) {
        this.$emit("saveModalForm", Object.assign({}, this.formModel));
        this.formModel = Object.assign({}, initialFormData);
      }
    },
    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.userGroup." + action
      );
    },
    setTranslatedAttributes(newItem = false) {
      if (newItem) {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            this.formModel.translations[language.lang] = {};
            this.formModel.translations[language.lang][attribute] = "";
          });
        });
      } else {
        this.languages.forEach((language) => {
          TRANSLATED_ATRIBUTES.forEach((attribute) => {
            if (!this.formModel.translations[language.lang]) {
              this.formModel.translations[language.lang] = {};
              this.formModel.translations[language.lang][attribute] = "";
            }
          });
        });
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
