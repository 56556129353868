<template>
  <div data-app>
    <v-card>
      <v-card-title>
        User Groups
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <UserGroupModalForm
            :loader="loader"
            :modalData="modalData"
            :statuses="statuses"
            :permissions="permissions"
            @closeModalForm="handleCloseModalForm"
            @saveModalForm="handleSaveModalForm"
          ></UserGroupModalForm>
        </v-col>
      </v-row>
      <AlertComponent
        :alertShow="alert.show"
        :alertText="alert.text"
        @closeAlert="alert.show = false"
      ></AlertComponent>
      <v-data-table
        :headers="headers"
        :items="userGroupCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="editItem"
      >
        <!-- <template v-slot:item.name="{ item }">
          {{ item.translations[appLocale].name }}
        </template> -->
        <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.system.user[item.status].value
          }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <!-- <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
            v-if="permissionCan('view')"
          >
            mdi-pencil
          </v-icon> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >OK</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import UserGroupModalForm, { initialFormData } from "./UserGroupModalForm";
import AlertComponent from "@/view/components/AlertComponent";
import i18nService from "@/core/services/i18n.service.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_USER_GROUPS,
  USER_GROUP_COLLECTION,
} from "@/core/services/store/userGroup.module.js";

export default {
  name: "UserGroups",
  components: { UserGroupModalForm, AlertComponent },
  data() {
    return {
      loader: false,
      appLocale: i18nService.getActiveLanguage(),
      users: [],
      search: "",
      permissions: [],
      headers: [
        {
          text: "Group name",
          value: "groupName",
        },
        { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      alert: {
        show: false,
        text: "You can't delete a user group that contains users",
      },
      modalData: {
        dialog: false,
        languages: i18nService.languages,
        selectedLocale: i18nService.languages[0],
        users: [],
        editedIndex: -1,
        editedItem: Object.assign({}, initialFormData),
        defaultItem: Object.assign({}, initialFormData),
      },
      dialogDelete: false,
    };
  },

  computed: {
    ...mapGetters([USER_GROUP_COLLECTION, "statuses"]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  methods: {
    ...mapActions([FETCH_USER_GROUPS]),
    getUsers() {
      ApiService.get("user")
        .then(({ data }) => {
          this.modalData.users = data;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },
    editItem(item) {
      if (this.permissionCan("view") && !this.dialogDelete) {
        ApiService.get("userGroup/" + item.id)
          .then(({ data }) => {
            this.modalData.editedIndex =
              this[USER_GROUP_COLLECTION].indexOf(item);

            // this.modalData.editedItem = Object.assign({}, data);
            this.modalData.editedItem = data;
            console.log(data);
            this.modalData.dialog = true;
            // console.log("this.modalData.editedItem-", this.modalData.editedItem);
            this.modalData.selectedLocale = i18nService.languages.find(
              (item) => {
                return item.lang == i18nService.getActiveLanguage();
              }
            );
          })
          .catch((error) => {
            console.log("Error!: ", error);
          });
      }
    },

    deleteItem(item) {
      // this.editedIndex = this.desserts.indexOf(item)
      this.editedIndex = this[USER_GROUP_COLLECTION].indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (this.editedItem.users.length > 0) {
        this.alert.show = true;
      } else {
        this.dialogDelete = true;
      }
    },

    deleteItemConfirm() {
      ApiService.delete("userGroup/" + this.editedItem.id)
        .then(({ data }) => {
          console.log(data);
          this.fetchUserGroups();
          this.getUsers();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.modalData.editedIndex = -1;
      this.modalData.editedItem = Object.assign({}, initialFormData);

      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    handleSaveModalForm(model) {
      /**Delete unused translations */
      this.modalData.languages.forEach((item) => {
        let langCode = item.lang;
        if (
          model.translations[langCode] &&
          model.translations[langCode].name == ""
        ) {
          delete model.translations[langCode];
        }
      });
      this.loader = true;
      if (this.modalData.editedIndex > -1) {
        model.status = parseInt(model.status);
        model.users = model.users.map(function (item) {
          return item.id;
        });
        ApiService.put("userGroup/" + model.id, model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUserGroups();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            this.warningAlert.text = error.message;
            this.warningAlert.show = true;
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      } else {
        //create model
        model.status = parseInt(model.status);
        model.users = model.users.map(function (item) {
          return item.id;
        });
        ApiService.post("userGroup", model)
          .then(({ data }) => {
            console.log(data);
            this.fetchUserGroups();
            this.handleCloseModalForm();
          })
          .catch((error) => {
            console.log("Error!: ", error);
          })
          .finally(() => {
            this.loader = false;
          });
      }
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "system.userGroup." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("userGroup") > -1
        );
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "userGroups" }]);
    this.fetchUserGroups();
    this.getUsers();
    this.setPermissions();
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
